import React , { Suspense, lazy } from "react";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Links} from './PathLink'
import {Loading} from '../custom-icons'
import {Authentication} from "./Authentication";
import {OrderProvider} from '../components/order/OrderProvider'

//import CustomTshirt from 'pages/customtshirt/index';

const Home = lazy(() => import('../components/Home'));
const About = lazy(() => import('../components/static-pages/About'));
const Terms = lazy(() => import('../components/static-pages/Terms'));
const Privacy = lazy(() => import('../components/static-pages/Privacy'));
const ShippingPolicy = lazy(() => import('../components/static-pages/ShippingPolicy'));
const SinglePg = lazy(() => import('../components/static-pages/SinglePg'));
const Page = lazy(() => import('../components/static-pages/Page'));
const RSSFeed = lazy(() => import('pages/RSSFeed'));
const Login = lazy(() => import('../components/auth/Login'));
const Register = lazy(() => import('../components/auth/Register'));
const MyWishlist = lazy(() => import('../components/MyWishlist'));
const Contact = lazy(() => import('../components/static-pages/contact'));

const Newarrivals = lazy(() => import('../components/products/Newarrivals'));
const FeaturedProduct = lazy(() => import('../components/products/FeaturedProduct'));
const PopularProducts = lazy(() => import('../components/products/PopularProducts'));
const Memeprintedtshirt = lazy(() => import('../components/products/Memeprintedtshirt'));
const CustomTshirt = lazy(() => import('pages/customtshirt/index'));
const Profile = lazy(() => import('../components/Profile'));
const ResetPassword = lazy(() => import('../components/auth/ResetPassword'));
const ChangePassword = lazy(() => import("../components/ChangePassword"));
const Logout = lazy(() => import("../components/Logout"));
const Checkout = lazy(() => import("../components/checkout/Checkout"));
const OrderDetail = lazy(() => import("../components/checkout/OrderDetail"));
const MyOrders = lazy(() => import("../components/MyOrders"));
const Addresses = lazy(() => import("../components/Addresses"));
const PageNotFound = lazy(() => import("../components/PageNotFound"));
const ProductDetailsSingle = lazy(() => import("components/ProductDetailsSingle"));
const ProductDetails = lazy(() => import("../components/ProductDetails"));
const Category = lazy(() => import("../components/category/Category"));
const AllPrintedTshirt = lazy(() => import("../components/category/AllPrintedTshirt"));
const SearchProducts = lazy(() => import("../components/category/SearchProducts"));
const ShowTestimonials = lazy(() => import("../components/testimonial/ShowTestimonials"));
const AddProduct = lazy(() => import("../Seller/AddProduct"));
const EditProduct = lazy(() => import("../Seller/EditProduct"));
const ProductList = lazy(() => import("../Seller/ProductList"));
const SetStore = lazy(() => import("../Seller/SetStore"));
const MyStore = lazy(() => import("../Seller/MyStore"));

const AllDigitalProducts = lazy(() => import("../components/DigitalProducts/AllDigitalProducts"));
const SellerStore = lazy(() => import("../components/DigitalProducts/SellerStore"));


//import ShowTestimonials from '../components/testimonial/ShowTestimonials'

// basename='/ctmri/'
// "homepage": "https://www.ctmrihub.com/",
const RoutePath = () => {
    return (        
        <Router basename='/' >
                <Suspense fallback={ <div className="w-full h-screen  flex items-center justify-center">
            <Loading className="animate-spin h-10 w-10 mr-2 text-gray-300 fill-accent inline" />
         </div>}>
            <Routes>
                <Route path='/' element={<Home />} />
                {/*<Route path='/home' element={<Home />} />
                <Route path='/' element={<Comingsoon />} />  */}
                <Route path={Links.CHANGE_PASSWORD} element={<Authentication><ChangePassword /></Authentication>} />
                <Route path={Links.PROFILE} element={<Authentication><Profile /></Authentication>} />
                <Route path={Links.MYADDRESSES} element={<Authentication><Addresses /></Authentication>} />
                <Route path={Links.MY_WISHLIST} element={<Authentication><MyWishlist /></Authentication>} />
                <Route path={Links.ORDERS} element={<OrderProvider><Authentication><MyOrders /></Authentication></OrderProvider>} />
                <Route path={Links.CHECKOUT} element={<Checkout />} />
                <Route path={`${Links.ORDERS}/:invoice`} element={<OrderDetail />} />
                <Route path={Links.LOGOUT} element={<Logout />} />
                <Route path={Links.CONTACT} element={<Contact />} />
                <Route path={Links.ABOUT} element={<About />} />
                <Route path={Links.TERMS} element={<Terms />} />
                <Route path={Links.PRIVACY} element={<Privacy />} />
                <Route path={Links.TESTIMONIALS} element={<ShowTestimonials />} />
                <Route path={Links.SHIPPINGPOLICY} element={<ShippingPolicy />} />
                <Route path={`${Links.PRODUCTP}/:slug`} element={<ProductDetailsSingle />} />
                <Route path={Links.CATEGORY} element={<Category />} />
                <Route path={Links.ALLPRINTEDTSHIRT} element={<AllPrintedTshirt />} />
                <Route path={`${Links.CATEGORY}/:slug`} element={<ProductDetails />} />
                <Route path={`${Links.CATEGORY}/:slug/:sub_slug`} element={<Category />} />
                <Route path={`/:slug`} element={<Category />} />
                <Route path={`${Links.CUSTOMTSHIRT}`} element={<CustomTshirt />} />
                <Route path={Links.LOGIN} element={<Login />} />
                <Route path={Links.REGISTER} element={<Register />} />
                
                <Route path={`${Links.RESET_PASSWORD}/:token`} element={<ResetPassword />} />
                <Route path={Links.NEWARRIVALS} element={<Newarrivals />} />
                <Route path={Links.FEATUREPRODUCT} element={<FeaturedProduct />} />
                <Route path={Links.POPULARPRODUCT} element={<PopularProducts />} />
                <Route path={Links.MEMEPRINTEDTSHIRT} element={<Memeprintedtshirt />} />
                <Route path={`${Links.T}/:page_slug`} element={<SinglePg />} />
                <Route path={`${Links.PAGE}/:pg_slug`} element={<Page />} />
                <Route path={`/search`} element={<SearchProducts />} />
                <Route path={`/rss`} element={<RSSFeed />} />
                <Route path="/404" element={<PageNotFound text='This page could not be found' />} /> 
                <Route path="*" element={<PageNotFound text='This page could not be found' />} /> 
                <Route path={Links.ADD_PRODUCT} element={<Authentication><AddProduct /></Authentication>} />
                <Route path={Links.MY_PRODUCTS} element={<Authentication><ProductList /></Authentication>} />
                <Route path={`${Links.MY_PRODUCTS}/edit/:p_id`} element={<Authentication><EditProduct /></Authentication>} />
                <Route path={Links.DIGITALPRODUCTS} element={<AllDigitalProducts />} />
                <Route path={`${Links.DIGITALPRODUCTS}/:slug`} element={<AllDigitalProducts />} />
                <Route path={`${Links.DIGITALPRODUCTS}/:slug/:sub_slug`} element={<AllDigitalProducts />} />
                <Route path={`${Links.STORE}/:slug`} element={<SellerStore />} />
                <Route path={Links.SET_STORE} element={<Authentication><SetStore /></Authentication>} />
                <Route path={Links.MY_STORE} element={<Authentication><MyStore /></Authentication>} />
            </Routes>
            </Suspense>
        </Router>
    );
  };
  
  export default RoutePath;
  