import React, { createContext, useContext, useState } from "react";

const OrderContext = createContext(null);

export const OrderProvider = ({ children }) => {
   const [selectedOrder, setSelectedOrder] = useState(null)
   
   
   const getSelectedOrder = (order) => {
      setSelectedOrder(order)
   }

   return <OrderContext.Provider value={{ selectedOrder, getSelectedOrder }}>{children}</OrderContext.Provider>;
};
export const useOrder = () => {
   return useContext(OrderContext);
};